<template>
  <!-- 商务合作独立页面（英文版） -->
  <div class="cooperationEn-scoped-page flex-col align-center">
    <div class="backTop"></div>
    <div class="box flex-col align-center">
      <div class="title-view flex-col align-center">
        <span class="span1"
          >Through Daping, companies or individuals can take control of their
          advertising for maximum effect</span
        >
        <span class="span2">Daping means “Big Screen” in Chinese</span>
        <span class="span3"
          >DOOH is a form of advertising known as digital-out-of-home, or
          digital outdoor advertising</span
        >
        <span class="span4">Key features</span>
      </div>
      <div class="listBox flex-col">
        <div
          class="list-item"
          v-for="(item, index) in contentList"
          :key="index"
        >
          <div
            v-if="(index + 1) % 2 === 1"
            class="item-view justify-between align-center"
          >
            <video class="video" :src="item.src" controls></video>
            <div class="content flex-col justify-center">
              <span class="title">{{ item.title }}</span>
              <span class="desc"
                >{{ item.describe
                }}<a v-if="item.a" :href="item.href" target="_blank">{{
                  item.a
                }}</a></span
              >
            </div>
          </div>
          <div v-else class="item-view justify-between align-center">
            <div class="content flex-col justify-center">
              <span class="title">{{ item.title }}</span>
              <span class="desc"
                >{{ item.describe
                }}<a v-if="item.a" :href="item.href" target="_blank">{{
                  item.a
                }}</a></span
              >
            </div>
            <video class="video" :src="item.src" controls></video>
          </div>
        </div>
      </div>
      <div class="footer-view flex-col">
        <div class="item align-center">
          <img class="logo" src="@/assets/icon/cooperationEnIcon1.png" alt="" />
          <div class="desc">
            If you want to experience the real effect, please visit our
            <span @click="goHome" class="span1">Chinese website</span>
          </div>
        </div>
        <div class="item align-center">
          <img class="logo" src="@/assets/icon/cooperationEnIcon2.png" alt="" />
          <div class="desc">
            If you want to lease our software, please email us at
            <span class="span2">support@daping.cn</span>
          </div>
        </div>
        <div class="item align-center">
          <img class="logo" src="@/assets/icon/cooperationEnIcon3.png" alt="" />
          <div class="desc">
            Address: Room 708, Phase II, Huiheng Building, Hi-tech South 7th
            Road, Nanshan District, Shenzhen, Guangdong, China
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contentList: [
        {
          src: "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/cooperationEn/cooperationEnVideo2.mp4",
          title: "Specify ad play time",
          describe:
            "Users can independently reserve the time for advertisement broadcast according to their own specific needs.",
        },
        {
          src: "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/cooperationEn/cooperationEnVideo3.mp4",
          title: "Specify the screen",
          describe:
            "Users can choose which outdoor LED screen to advertise on.",
        },
        {
          src: "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/cooperationEn/cooperationEnVideo4.mp4",
          title: "Place order online",
          describe:
            "Users can place orders online without manual intervention. After the order is approved, the system will automatically play the advertising video according to the order instruction.",
        },
        {
          src: "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/cooperationEn/cooperationEnVideo5.mp4",
          title: "Sharing order",
          describe:
            "Users can share their orders with others, who can then also see such information as the order's advertising schedule.",
        },
        {
          src: "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/cooperationEn/cooperationEnVideo6.mp4",
          title: "Smart settings",
          describe:
            "The user only needs to set it once. The system will play videos continuously for multiple days, automatically selecting peak hours for playback.",
        },
        {
          src: "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/cooperationEn/cooperationEnVideo7_20240220.mp4",
          title: "Monitoring",
          describe:
            "Everyone can remotely view what is being played on the big screen. ",
          a: "Click to view",
          href: "https://www.daping.cn/p2pVisitor/",
        },
        {
          src: "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/cooperationEn/cooperationEnVideo8.mp4",
          title: "Both businesses and individuals can advertise",
          describe:
            "Enterprises can launch advertising videos to gain more exposure for their brands or products; individuals can project personal messages such as proposals, birthday wishes, congratulations, blessings and other videos to loved ones on the big screen.",
        },
        {
          src: "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/cooperationEn/cooperationEnVideo9.mp4",
          title: "Custom looping promotion video",
          describe:
            "During available time (ad time that has not been otherwise purchased), your promotional videos will be played. There is no limit to the number of promotional videos you may upload, and they will be played in a continuous loop.",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 获取所有视频元素
      const videos = Array.from(document.getElementsByTagName("video"));
      // 为每个视频添加事件监听器
      videos.forEach((video) => {
        video.addEventListener("play", (event) => {
          // 暂停其他所有视频
          videos.forEach((otherVideo) => {
            if (otherVideo !== event.target) {
              otherVideo.pause();
            }
          });
        });
      });
    });
  },
  methods: {
    goHome() {
      let BASE_URL = "";
      if (process.env.NODE_ENV == "development") {
        BASE_URL = `${window.location.protocol}//${window.location.host}`; // 开发版本使用
      } else {
        BASE_URL = process.env.VUE_APP_BASE_URL;
      }
      window.open(BASE_URL);
    },
  },
};
</script>

<style lang="scss">
.cooperationEn-scoped-page {
  width: 100%;
  background: #18181b;
  min-height: calc(100vh - 70rem);
  padding-bottom: 138rem;
  .backTop {
    width: 100%;
    height: 900rem;
    position: absolute;
    top: 0;
    left: 0;
    background: url("../../assets/img/screenCooperateTopEn.png");
    background-size: 100% 100%;
  }
  .box {
    width: 1280rem;
    margin-top: 830rem;
    .title-view {
      width: 100%;
      padding-top: 38rem;
      .span1 {
        font-size: 26rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 37rem;
      }
      .span2 {
        font-size: 20rem;
        color: rgba(255, 255, 255, 0.65);
        line-height: 28rem;
        margin-top: 20rem;
      }
      .span3 {
        font-size: 20rem;
        color: rgba(255, 255, 255, 0.65);
        line-height: 28rem;
        margin-top: 15rem;
      }
      .span4 {
        font-size: 50rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 70rem;
        margin-top: 70rem;
      }
    }

    .listBox {
      width: 100%;

      .list-item {
        width: 100%;
        margin-top: 100rem;
        .item-view {
          width: 100%;
          .video {
            width: 554rem;
            height: 311rem;
            border-radius: 8rem;
          }
          .content {
            width: 666rem;
            .title {
              font-size: 40rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 56rem;
            }
            .desc {
              font-size: 24rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: rgba(255,255,255,0.85);
              line-height: 33rem;
              margin-top: 20rem;
              a {
                color: #28dae3;
              }
            }
          }
        }
      }
    }
    .footer-view {
      width: 100%;
      height: 214rem;
      background: linear-gradient(93deg, #004C61 0%, #622B60 100%);
      border-radius: 18rem;
      padding: 15rem 25rem 0;
      margin-top: 120rem;
      .item {
        margin-top: 25rem;
        .logo {
          width: 24rem;
          height: 24rem;
          margin-right: 14rem;
        }
        .desc {
          font-size: 20rem;
          color: #ffffff;
          line-height: 28rem;
          .span1 {
            color: #28dae3;
            text-decoration: underline;
            cursor: pointer;
          }
          .span2 {
            color: #28dae3;
          }
        }
      }
    }
  }
}
/* 自定义谷歌等浏览器滚动条样式 */
::-webkit-scrollbar {
  width: 10rem;
  height: 10rem;
  background-color: rgba(24, 24, 27, 1)
}

::-webkit-scrollbar-thumb {
  background-color: rgba(230, 230, 230, 1);
  border-radius: 5rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 自定义火狐浏览器滚动条样式 */
* {
  scrollbar-color: #E8E5E9 #18181B;
  scrollbar-width: thin;
}
</style>
